
import { Action } from "vuex-class";
import { bus } from "@/main";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { FormBase, FormSubmit } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { formatErrors } from "@/utils/formatters";
import { ActivitySeller } from "@/store/modules/activity-seller.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormSubmit,
    },
})
export default class SellerReinviteModal extends Mixins(HasFormErrors) {
    @Action("invite/resendActivityInvite") resendActivityInvite!: (payload: { activity_id: number; contact_id: number; activity_seller_id: number }) => Promise<number>;

    @Ref() form!: FormClass;

    activitySeller: ActivitySeller | null = null;

    show: boolean = false;

    mounted() {
        bus.$off("show-resend-seller-invite");
        bus.$on("show-resend-seller-invite", (activitySeller: ActivitySeller) => {
            this.activitySeller = activitySeller;

            this.show = true;
        });
    }

    async submit(form: FormClass) {
        try {
            if (!this.activitySeller?.activity_id || !this.activitySeller?.seller_id) {
                return;
            }

            await this.resendActivityInvite({ activity_id: this.activitySeller?.activity_id, contact_id: this.activitySeller?.seller_id, activity_seller_id: this.activitySeller.id });

            this.$toast.open({ message: this.$t("views.seller.reinvite.success") as string, type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.activitySeller = null;

        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
